<template>
  <div class="panel">
    <div class="main">
      <div v-if="selectedProject == null" class="d-flex justify-content-center mt-5">
        <b-spinner variant='primary' style='width: 3rem; height: 3rem;'></b-spinner>
      </div>
      <section v-else class='w-100 m-auto pt-5'>
        <h4 class='d-inline px-5'>Scenarios</h4>
      </section>
      <div class='bg-white p-5 table-container' v-if='selectedProject && tableItems.length > 0'>
        <div class="d-flex flex-column h-100" v-for="studyTableItems in tableItems" :key="studyTableItems.studyName">
          <div class="d-flex flex-row align-items-center justify-content-between">
            <h5>{{ studyTableItems.study.name }}</h5>
            <b-button v-if="canAddScenario && (isProjectInCompany || userIsRWDI)" size="sm" variant="primary" @click="createNewScenario()">+ Add Scenario</b-button>
          </div>
          <b-container fluid class="pl-0">
            <b-table
            v-if="studyTableItems.configs.length > 0"
            head-variant="light"
            class='border'
            style='font-size: 0.875rem;'
            sticky-header
            hover
            small
            :fields="tableFields"
            :items="studyTableItems.configs">
              <template #cell(scenario)="row">
                <span class='scenario-name' @click='viewScenario(studyTableItems.study, row.item.scenario, row.item.configurationId)'>
                  {{ row.value }}
                </span>
              </template>
              <template #cell(created_date)="row">
                {{ new Date(row.item.created_date).toLocaleDateString() }}<br>
                {{ new Date(row.item.created_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }}
              </template>
              <template #cell(simulated_date)="row">
                <span v-if="row.item.simulated_date">
                  {{ new Date(row.item.simulated_date).toLocaleDateString() }}<br>
                  {{ new Date(row.item.simulated_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }}
                </span>
              </template>
              <template v-slot:cell(edit)="row" v-if="(isProjectInCompany || userIsRWDI) && canEditScenario">
                <b-icon
                  icon='pencil'
                  role='button'
                  class="pencil-icon"
                  @click="editScenario(row.item.scenario, row.item.configurationId, row.item.id)">
                </b-icon>
              </template>
               <template v-slot:cell(duplicate)="row" v-if="(isProjectInCompany || userIsRWDI) && canAddScenario">
                <b-icon
                  icon='files'
                  role='button'
                  class="duplicate-icon"
                  @click="duplicateScenario(row.item.configurationId)">
                </b-icon>
              </template>
              <template v-slot:cell(delete)="row" v-if="isProjectInCompany && canEditScenario">
                <b-icon
                  icon='trash'
                  role='button'
                  class="delete-icon"
                  @click="toggleDeleteModal(row.item.configurationId)">
                </b-icon>
              </template>
            </b-table>
            <span v-else>
              There are no scenarios for this study.
            </span>
          </b-container>
          <b-modal centered v-model="isDeleteModalOpen">
            <template #modal-header>
              <h4>Delete Scenario</h4>
            </template>

            <template #default>
              <p>Are you sure you want to delete this scenario?</p>
            </template>

            <template #modal-footer>
              <b-button @click="isDeleteModalOpen = false" class='float-left remove-project-btn'>No</b-button>
              <b-spinner class="confirmation-spinner" variant="primary" small v-if="deleting"></b-spinner>
              <b-button @click="hideScenario" variant='danger' :disabled="deleting" class='float-right remove-project-btn'>Yes</b-button>
            </template>
          </b-modal>
        </div>
      </div>
      <edit-scenario-modal v-if="openEditModal" @close-modal="openEditModal=false" 
      :showModal="openEditModal" 
      :scenarioName="scenarioNameToEdit" 
      :configurationId="configurationIdToEdit"
      :simulationId="simulationIdToEdit"
      :correctSimulation="true"
      ></edit-scenario-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ADD_SCENARIO, EDIT_SCENARIO } from '@/constants/permissions';
import EditScenarioModal from '@/components/projects/list/EditScenarioModal';


export default {
  name: 'ProjectSettingsScenario',
  components: {
    EditScenarioModal
  },
  data() {
    return {
      openEditModal: false,
      isDeleteModalOpen: false,
      scenarioToHideConfigurationId: null,
      deleting: false,
      tableItems: [],
      simulationLabels: []
    };
  },
  props: {
    canEditProject: {
      required: false
    },
  },
  created() {
    if (this.selectedProject) {
      this.getSimulationLabels();
      this.getTableItems();
    }
  },
  computed: {
    isProjectInCompany() {
      return this.loggedInUser?.is_superuser || this.selectedProject?.company_id == this.loggedInUser?.company_id;
    },
    userIsRWDI() {
      return this.userCompany?.is_rwdi;
    },
    tableFields() {
      let table_fields = [
        {key: 'scenario',
          thClass: 'text-left',
          tdClass: 'text-left'},
        {key: 'status',
          thClass: 'text-center',
          tdClass: 'text-center'},
        {key: 'type',
          thClass: 'text-center',
          tdClass: 'text-center'},
        {key: 'created_date',
          thClass: 'text-center',
          tdClass: 'text-center'},
        {key: 'simulated_date',
          thClass: 'text-center',
          tdClass: 'text-center'}
      ];

      if (this.isProjectInCompany || this.userIsRWDI) {
        if (this.canEditScenario) {
          table_fields.push({key: 'edit',
            thClass: 'text-center',
            tdClass: 'text-center'});
        }
        if (this.canAddScenario) {
          table_fields.push({key: 'duplicate',
            thClass: 'text-center',
            tdClass: 'text-center'});
        }
      }

      if (this.isProjectInCompany && this.canEditScenario) {
        table_fields.push({key: 'delete',
          thClass: 'text-center',
          tdClass: 'text-center'});
      }

      return table_fields;
    },
    canAddScenario() {
      return (this.$store.getters['allowedPermissions'] || {})[ADD_SCENARIO];
    },
    canEditScenario() {
      return (this.$store.getters['allowedPermissions'] || {})[EDIT_SCENARIO];
    },
    ...mapGetters(['loggedInUser', 'userCompany']),
    ...mapGetters('project', ['selectedProject'])
  },
  methods: {
    createNewScenario() {
      this.$emit('addNewScenario', Number(this.$route.params.id), Number(this.$route.params.study));
    },
    getSimulationLabels() {
      this.simulationLabels = [];
      for(let i = 0; i<this.selectedProject.studies.length; i++) {
        this.simulationLabels.push({ study: this.selectedProject.studies[i] });

        if(this.loggedInUser?.is_superuser || this.selectedProject?.is_demo_project || this.userCompany?.is_rwdi) {
          this.simulationLabels[i].configs = this.selectedProject.studies[i]?.simulation_labels;
        } else if (!this.selectedProject?.studies) {
          this.simulationLabels[i].configs = [];
        } else if(!this.isProjectInCompany) {
          this.simulationLabels[i].configs = this.selectedProject.studies[i]?.simulation_labels.filter(sim => (sim.category === 'RESULT'));
        } else if(this.canAddScenario) {
          // 1.	a user has submitter role and subscription assigned: they can see all scenarios (INBOUNDS and RESULTS, MONTHLY and ONE-TIME)
          if (this.loggedInUser?.subscription) {
            this.simulationLabels[i].configs = this.selectedProject.studies[i]?.simulation_labels;
          } else { // 2.	a user has submitter access but no subscription: they can see all completed scenarios and only INBOUNDS ones if they are ONE-TIME
            this.simulationLabels[i].configs = this.selectedProject.studies[i]?.simulation_labels.filter(sim => (sim.category === 'RESULT')).concat(this.selectedProject.studies[i]?.simulation_labels.filter(sim => (sim.category !== 'RESULT' && sim.subscription_type === 'ONE-TIME')));
          }
        } else { // 3.	a user has Read-Only access: they can see only RESULT scenarios
          this.simulationLabels[i].configs = this.selectedProject.studies[i]?.simulation_labels.filter(sim => (sim.category === 'RESULT'));
        }
      }
    },
    getTableItems() {
      this.tableItems = [];
      let iterationsControl = 1;
      if (this.userCompany?.is_rwdi) {
        iterationsControl = this.simulationLabels.length;
      }
      for(let i = 0; i<iterationsControl; i++) {
        this.tableItems.push({ study: this.simulationLabels[i].study, configs: [] });
        for (const label of this.simulationLabels[i].configs) {
          this.tableItems[i].configs.push({
            id: label.id,
            scenario: label.label,
            status: label.category === 'RESULT' ? 'Complete' : (label.category === 'INBOUND' ? 'Not Submitted' : 'In Progress'),
            type: label.simulation_type === 'ML' ? 'AI' : label.simulation_type,
            configurationId: label.configurationId,
            created_date: label.created_at,
            simulated_date: label.sim_date_completed
          });
        }
      }
    },
    viewScenario(study, label, configurationId) {
      this.$store.dispatch('project/setSelectedScenarioFromProperties', { label: label, configurationId: configurationId });
      this.$store.dispatch('project/setViewerMode', 'Viewer');
      this.$router.push({
        name: 'ViewerContainer',
        params: {
          tabName: 'Viewer',
          study: study.id,
          configuration: configurationId
        },
      });
    },
    async hideScenario() {
      this.deleting = true;
      await this.$store.dispatch('project/hideScenario', {
        projectId: Number(this.$route.params.id),
        studyId: Number(this.$route.params.study),
        configurationId: this.scenarioToHideConfigurationId
      });

      // this is an inefficient way to do this, ideally we would pass the simulationLabels list index and access it directly instead of looping through the whole list
      for(let i = 0; i<this.simulationLabels.length;i++) {
        this.simulationLabels[i].configs = this.simulationLabels[i].configs.filter(simulationLabel => this.scenarioToHideConfigurationId !== simulationLabel.configurationId);
      }
      this.getTableItems();
      this.scenarioToHideConfigurationId = null;
      this.deleting = false;
      this.isDeleteModalOpen = false;      
    },
    async editScenario(configurationName, configurationId, simulationId) {
      this.configurationIdToEdit = configurationId;
      this.scenarioNameToEdit = configurationName;
      this.simulationIdToEdit = simulationId;
      this.openEditModal = !this.openEditModal;
    },
    toggleDeleteModal(configurationId) {
      this.scenarioToHideConfigurationId = configurationId;
      this.isDeleteModalOpen = !this.isDeleteModalOpen;
    },
    async duplicateScenario(scenarioToDuplicate) {
      try {
        // Fetch the original scenario details
        const originalScenario = this.tableItems.flatMap(study => study.configs)
          .find(config => config.configurationId === scenarioToDuplicate);

        if (!originalScenario) {
          throw new Error('Scenario not found - impossible to duplicate this scenario');
        }
        this.$emit('duplicateScenario', Number(this.$route.params.id), Number(this.$route.params.study), originalScenario);
      } catch (error) {
        console.error('Error duplicating scenario:', error);
      }
    },
  },
  watch: {
    simulationLabels(newValue) {
      if (newValue) {
        this.getTableItems();
        this.$store.dispatch('project/selectSimulationLabels', this.simulationLabels[0].configs);
      }
    }
  }
};
</script>

<style scoped>
.panel {
  margin: 0;
  background-color: #fff;
  min-height: 20em;
  display: flex;
}

.main {
  padding: 1rem;
  width: 100%;
}

.scenario-name {
  text-decoration: underline;
  color: #007bff;
}

.scenario-name:hover {
  cursor: pointer;
}

.confirmation-spinner {
  right: 15%;
  position: absolute;
  bottom: 8%;
}

.table-container {
  margin-top: 0;
  padding-top: 3% !important;
}
</style>